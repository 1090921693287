* {
  color: var(--subColor_01);
  word-break: keep-all;
  background-repeat: no-repeat;
}

body {
  margin: 0;
  font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

fieldset {
  border: 0px none;
}

label {
  font-weight: inherit;
  margin: 0px;
}

input[type=checkbox],
input[type=radio] {
  margin: 0px;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
}

input[type="search"]::-moz-search-cancel-button {
  -moz-appearance: none;
  appearance: none;
}

input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.loading {
  text-indent: -9999px;
}

.swiper-button-prev,
.swiper-button-next {
  width: auto;
  height: auto;
  margin-top: 0px;
}

/* 전체 바 색상 */
#nprogress .bar {
  /* height: 4px; */
  background: red !important;
}

/* 스피너 색상 */
#nprogress .spinner-icon {
  display: none;
}

/* 페이딩 효과 */
#nprogress .peg {
  box-shadow: none;
}

:root {
  --mainColor_01: #E85400;
  --mainColor_02: #F3851E;
  --mainColor_03: #F09519;
  --subColor_01: #202020;
  --subColor_02: #484848;
  --subColor_03: #707070;
  --subColor_04: #AEAEAE;
  --subColor_05: #E4E4E4;
  --subColor_06: #FCFCFC;
  --subColor_07: #A4A4A4;
  --subColor_08: #F5F5F5;
  --subColor_09: #D5D5D5;
}

.btn.type_01 {
  width: 240px;
  height: 64px;
  color: #fff;
  background-color: var(--subColor_01);
}

.icon_loading_wrap {
  overflow: hidden;
}

.icon_loading_wrap .icon_loading {
  width: 100%;
  text-align: center;
  font-size: 26px;
  padding: 50px 0px;
  color: var(--subColor_04);
}

.wrap {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.hide {
  display: none;
}

.mt_130 {
  margin-top: 130px;
}

.mt_40 {
  margin-top: 40px;
}

.mt_24 {
  margin-top: 24px;
}

.mb_0 {
  margin-bottom: 0px !important;
}

.mb_16 {
  margin-bottom: 16px;
}

.mb_24 {
  margin-bottom: 24px;
}

.mb_40 {
  margin-bottom: 40px;
}

.mb_48 {
  margin-bottom: 48px;
}

.mb_60 {
  margin-bottom: 60px;
}

.mb_80 {
  margin-bottom: 80px;
}

.mb_120 {
  margin-bottom: 120px;
}

.Montserrat {
  font-family: "Montserrat", sans-serif;
}

.txt_align_center {
  text-align: center;
}

.lh_type_08 {
  line-height: 56px;
}
.lh_type_09{
  line-height: 52px;
}

.lh_type_01 {
  line-height: 48px;
}

.lh_type_02 {
  line-height: 36px;
}

.lh_type_03 {
  line-height: 32px;
}

.lh_type_04 {
  line-height: 30px;
}

.lh_type_07 {
  line-height: 28px;
}

.lh_type_05 {
  line-height: 26px;
}

.lh_type_06 {
  line-height: 20px;
}

.fs_type_15 {
  font-size: 64px;
}

.fs_type_10 {
  font-size: 56px;
}

.fs_type_01 {
  font-size: 48px;
}

.fs_type_12 {
  font-size: 42px;
}

.fs_type_13 {
  font-size: 40px;
}
.fs_type_16{
  font-size: 36px;
}
.fs_type_02 {
  font-size: 32px;
}

.fs_type_03 {
  font-size: 30px;
}

.fs_type_14 {
  font-size: 28px;
}

.fs_type_04 {
  font-size: 26px;
}

.fs_type_05 {
  font-size: 24px;
}

.fs_type_06 {
  font-size: 20px;
}

.fs_type_07 {
  font-size: 18px;
}

.fs_type_11 {
  font-size: 17px;
}

.fs_type_08 {
  font-size: 16px;
}

.fs_type_09 {
  font-size: 14px;
}

.fw_type_light {
  font-weight: 300;
}

.fw_type_01 {
  font-weight: 400;
}

.fw_type_02 {
  font-weight: 500;
}

.fw_type_03 {
  font-weight: 600;
}

.fw_type_04 {
  font-weight: 700;
}

.section_01 .tit+.txt {
  margin-top: 24px;
}

.mb_swiper_nav_wrap {
  z-index: 9;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 64px;
  padding: 0px 80px;
  pointer-events: none;
}

#root .swiper-button-next.type_01,
#root .swiper-button-prev.type_01 {
  pointer-events: all;
  position: static;
}

#root .mb_swiper_wrap .swiper-button-next:after,
#root .mb_swiper_wrap .swiper-button-prev:after {
  position: static;
  font-family: "xeicon" !important;
  font-size: 64px;
  color: rgba(255, 255, 255, 0.6)
}

#root .mb_swiper_wrap .swiper-button-next:after {
  content: "\e93e";
}

#root .mb_swiper_wrap .swiper-button-prev:after {
  content: "\e93b";
}

.btn:active,
.btn.active {
  box-shadow: none;
}

.btn_a.type_01 {
  position: relative;
  display: flex;
  gap: 34px;
  width: fit-content;
}

#root .btn_a.type_01.pc {
  display: flex;
}

.tit.type_01 {
  margin-bottom: 16px;
  color: var(--mainColor_01);
}

.tit.type_03 {
  margin-bottom: 40px;
}

.tit.type_04 {
  margin-bottom: 80px;
}

.btn_a.type_01 .xi-long-arrow-right {
  position: relative;
  z-index: 9;
  transition: all 0.2s;
  font-size: 14px;
  color: #fff;
}

.btn_a.type_01:hover .xi-long-arrow-right {
  padding-right: 15px;
}

.btn_a.type_01::after {
  content: '';
  display: block;
  clear: both;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  transition: all 0.25s;
  border-radius: 50%;
  background-color: var(--mainColor_01);
}

.btn_a.type_01:hover::after {
  width: 100%;
  border-radius: 9999px;
}

.btn_a.type_01 .txt {
  z-index: 9;
  position: relative;
  transition: all 0.2s;
}

.btn_a.type_01:hover .txt {
  padding-left: 15px;
  color: #fff;
}

.top_nav.type_01 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid var(--subColor_01);
  padding-bottom: 32px;
  margin-bottom: 24px;
}

.top_nav.type_01 .search_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 400px;
  height: 50px;
  border: 2px solid var(--subColor_05);
  padding: 0px 8px 0px 24px;
}

.top_nav.type_01 .search_box #search {
  width: 80%;
}

.top_nav.type_01 .search_box #product_search {
  width: 85%;
  height: 100%;
}

.top_nav.type_01 .search_box .icon_search {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  font-size: 19px;
  border-radius: 50%;
  color: #fff;
  background-color: var(--mainColor_01);
}

.page_control_wrap.type_01 {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid var(--subColor_01);
  padding-top: 40px;
  margin-top: 40px;
}

.page_control_wrap.type_01 .control_btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.page_control_wrap.type_01 .control_btn.on {
  color: #fff;
  background-color: #333;
}

.info_wrap.type_01 {
  padding: 32px 0px;
  border-top: 2px solid var(--subColor_01);
  border-bottom: 1px solid #dedede;
}

.info_wrap.type_01 .category {
  line-height: 18px;
  margin-bottom: 8px;
  color: var(--subColor_04);
}

.info_wrap.type_01 .sub_info_wrap {
  display: flex;
  gap: 24px;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.info_wrap.type_01 .date_wrap,
.info_wrap.type_01 .views_wrap {
  display: flex;
  gap: 8px;
}

.contents_wrap.type_01 {
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding-bottom: 80px;
}

.contents_wrap.type_01 img {
  display: block;
  width: max-content;
  max-width: calc(100% - 32px);
  margin: 0px auto;
}

.control_wrap.type_01 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid var(--subColor_01);
  padding-top: 24px;
}

.control_wrap.type_01 * {
  color: var(--subColor_04);
}

.control_wrap.type_01 .btn,
.control_wrap.type_01 .prev_wrap,
.control_wrap.type_01 .next_wrap {
  display: flex;
  align-items: center;
}

.control_wrap.type_01 .btn {
  gap: 16px;
}

.control_wrap.type_01 .prev_wrap,
.control_wrap.type_01 .next_wrap {
  gap: 8px;
}

.control_wrap.type_01 .btn_list {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border: 2px solid var(--mainColor_01);
  border-radius: 50%;
}

.control_wrap.type_01 .btn_list .icon {
  color: var(--mainColor_01);
}

.control_wrap.type_01 {
  margin-bottom: 120px;
}

#root .list_wrap .a_btn .icon_wrap.type_01 {
  display: flex;
}

.list_wrap .a_btn .icon_wrap.type_01 {
  justify-content: center;
  align-items: center;
  width: 33px;
  min-width: 33px;
  height: 33px;
  border-radius: 50%;
  border: 1px solid var(--subColor_04);
  margin-left: auto;
}

.list_wrap .a_btn:hover .icon_wrap.type_01,
.list_wrap .a_btn:focus .icon_wrap.type_01 {
  border-color: var(--mainColor_01);
}

.list_wrap .a_btn .icon_wrap.type_01 .icon {
  color: var(--subColor_04);
}

.list_wrap .a_btn:hover .icon_wrap.type_01 .icon,
.list_wrap .a_btn:focus .icon_wrap.type_01 .icon {
  color: var(--mainColor_01);
}

.btn_download {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 52px;
  border-radius: 4px;
  padding: 0px 24px;
  margin-top: 80px;
  background-color: var(--subColor_08);
}

.btn_download .icon {
  color: var(--subColor_07);
}

.btn_download .icon_download {
  margin-left: auto;
}

.btn_download .txt {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* header Start */
#root #header.on,
#root #header.active {
  border-bottom: 1px solid var(--subColor_05);
  background-color: #fff;
}

#root #header.on *,
#root #header.active * {
  color: var(--subColor_01);
}

#root #header .logo.black {
  display: none;
}

#root #header.on .logo.white,
#root #header.active .logo.white {
  display: none;
}

#root #header.on .logo.black,
#root #header.active .logo.black {
  display: block;
}

#header {
  z-index: 999;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 80px;
}

#gnb {
  height: 100%;
}

#gnb .gnb_ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0px 40px;
}

#gnb .logo {
  width: auto;
  height: 48px;
}

#gnb .gnb_item_category {
  height: 100%;
}

#gnb .category_wrap {
  height: 100%;
  display: flex;
  gap: 82px;
}

#gnb .category_wrap .item {
  position: relative;
}

#gnb .category_wrap .item .category {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 17px;
  font-weight: 500;
  color: #fff;
}

#gnb .category_wrap .item:hover .gnb_sub {
  display: block;
}

#gnb .gnb_sub {
  display: none;
  position: absolute;
  top: 79px;
  left: 50%;
  transform: translateX(-50%);
  width: max-content;
  text-align: center;
  padding: 8px 0px;
  background-color: #fff;
}

#gnb .gnb_sub .a_btn {
  padding: 16px 30px;
}

#gnb .gnb_icon {
  font-size: 40px;
}

.xi-align-right.white {
  color: #fff;
}

/* header End */

/* home Start */
#home .mb_swiper_wrap {
  overflow: hidden;
  height: 830px;
}

#home .mb_swiper_wrap .swiper {
  height: 100%;
}

#home .mb_swiper_wrap .swiper-slide {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

#home .main_banner {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#home .mb_swiper_wrap .swiper-slide::before {
  content: "";
  display: block;
  clear: both;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

#home .mb_swiper_wrap .swiper-slide .tit_wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  font-size: 64px;
  padding: 0px 240px;
}

#home .mb_swiper_wrap .swiper-slide .tit {
  line-height: 80px;
}

#home .mb_swiper_wrap .swiper-slide .tit_wrap * {
  color: #fff;
}

/* .mb_swiper_wrap .swiper-slide .txt_wrap .txt_stroke{
  -webkit-text-stroke: 2px #fff;
  color: transparent;
} */

#home {
  background-color: #f2f2f2;
}

#home .tit.type_01,
#home .tit.type_02 {
  line-height: 1em;
  text-align: center;
  font-weight: 700;
}

#home .tit.type_01 {
  font-size: 100px;
  margin-bottom: -20px;
  color: #fff;
}

#home .tit.type_02 {
  font-size: 48px;
  color: var(--subColor_01);
}

#home .tit.type_02 .point {
  color: var(--mainColor_01);
}

#home .section_01 {
  z-index: 99;
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-top: -95px;
}

#home .section_01 .item_01 {
  width: 825px;
  height: 247px;
  padding: 48px 80px;
  background-image: url(../images/home/section_01_bg.webp);
  background-size: cover;
  background-position: bottom center;
}

#home .section_01 .item_01 * {
  text-align: right;
  color: #fff;
}

#home .item_02 {
  display: flex;
}

#home .item_02 .sub {
  position: relative;
  width: 285px;
  height: 152px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#home .item_02 .sub:not(:last-of-type)::after {
  content: "";
  display: block;
  clear: both;
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 1px;
  height: 58px;
  background-color: #d5d5d5;
}

#home .item_02 .sub .contents_wrap {
  text-align: center;
}

#home .item_02 .sub .icon {
  width: auto;
  height: 45px;
  margin-bottom: 16px;
}

#home .section_02 .item_01 {
  display: flex;
  justify-content: space-between;
  padding: 120px 0px 80px 0px;
}

#home .section_02 .item_01 .sub_tit {
  font-size: 24px;
  font-weight: 500;
  color: rgba(16, 16, 16, 0.4)
}

#home .section_02 .item_01 .zeatech_img {
  width: 280px;
  height: auto;
  margin-bottom: 36px;
}

#home .section_02 .item_01 .txt_wrap {
  width: 100%;
  max-width: 740px;
}

#home .section_02 .item_01 .txt_wrap .tit {
  margin-bottom: 40px;
}

#home .section_02 .mv {
  display: block;
}

#home .section_02 .mv.mo {
  display: none;
}

#home .section_03 {
  padding: 160px 0px 200px 0px;
}

#home .section_03 .txt,
#home .section_05 .txt {
  text-align: center;
}

#home .section_03 .swiper_02 {
  padding-bottom: 5px;
}

#home .section_03 .swiper_02 .swiper-slide {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 480px;
  height: 480px;
  padding: 40px;
}

#home .section_03 .swiper_02 .swiper-slide-active {
  border-top: 8px solid var(--mainColor_01);
  box-shadow: 2px 3px 5px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
}

#home .section_03 .swiper_02_nav_wrap {
  display: flex;
  justify-content: space-between;
}

#home .section_03 .swiper_02_nav_wrap .swiper-button-next:after,
#home .section_03 .swiper_02_nav_wrap .swiper-button-prev:after {
  content: none;
}

#home .section_03 .swiper_02_nav_wrap .swiper-button-next,
#home .section_03 .swiper_02_nav_wrap .swiper-button-prev {
  width: auto;
  margin-top: 0px;
  display: flex;
  align-items: center;
  gap: 16px;
}

#home .section_03 .swiper_02_nav_wrap .swiper-button-next *,
#home .section_03 .swiper_02_nav_wrap .swiper-button-prev * {
  color: var(--subColor_04);
}

#home .section_03 .swiper_02_nav_wrap .swiper-button-next:hover *,
#home .section_03 .swiper_02_nav_wrap .swiper-button-prev:hover * {
  color: var(--mainColor_01);
}

#home .section_03 .swiper_02 .swiper-slide .txt_wrap {
  width: 100%;
}

#home .section_03 .swiper_02 .swiper-slide .txt_wrap * {
  text-align: left
}

#home .section_03 .swiper_02 .swiper-slide .txt_wrap .sub_txt_wrap {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
}

#home .section_03 .swiper_02 .swiper-slide .txt_wrap .sub_txt_wrap .txt {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 50%;
  color: var(--mainColor_01);
}

#home .section_03 .swiper_02 .swiper-slide .txt_wrap .sub_txt_wrap .txt:not(:last-of-type) {
  border-right: 1px solid var(--subColor_04);
  padding-right: 8px;
}

#home .section_03 .swiper_02 .swiper-slide .product_img_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 260px;
  height: 260px;
  margin: auto auto 0px auto;
}

#home .section_03 .swiper_02 .swiper-slide .product_img {
  display: block;
  width: 100%;
}

/* #home .section_04 {
  height: 685px;
  padding: 80px 0px;
  background-image: url(../images/home/zea_tech_bg.webp);
  background-size: cover;
}

#home .section_04 .logo {
  display: block;
  width: 185px;
  height: auto;
  margin: 0px auto;
}

#home .section_04 .txt {
  text-align: center;
  color: #fff;
}

#home .section_04 .list_wrap {
  display: flex;
  width: fit-content;
  margin: 0px auto;
}

#home .section_04 .list_item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
  width: 267px;
  height: 267px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.15)
}

#home .section_04 .list_item:not(:first-of-type) {
  margin-left: -25px;
}

#home .section_04 .icon {
  width: auto;
  height: 63px;
}

#home .section_04 .icon+.txt {
  line-height: 25px;
  color: #fff;
} */
#home .section_04{
  background-image: url(../images/home/section04_bg.webp);
  background-size: cover;
}
#home .section_04>.wrap{
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 570px;
}
#home .section_04>.wrap .tit{
  color: #fff;
}
#home .section_04>.wrap .mv{
  width: 618px;
  max-width: 45%;
  height: auto;
}

#home .section_05 {
  padding: 160px 0px;
}

#home .section_05 .list_wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px 16px;
  max-width: 1050px;
}

#home .section_05 .logo {
  width: auto;
  height: 100px;
}

#home .section_06 {
  display: flex;
  align-items: flex-start;
  padding-bottom: 200px;
}

#home .section_06 .tit {
  text-align: left;
}

#home .section_06 .tit.type_01 {
  margin-left: -47%;
}

#home .section_06 .list_wrap {
  width: 1108px;
  margin-top: 80px;
  border-top: 2px solid var(--subColor_01);
  border-bottom: 2px solid var(--subColor_01);
}

#home .section_06 .list_wrap .notice_item:not(:last-of-type) {
  border-bottom: 1px solid #d5d5d5;
}

#home .section_06 .list_wrap .notice_item .btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 53px 40px 0px;
}

#home .section_06 .list_wrap .notice_item .btn .txt {
  color: var(--subColor_04);
}

#home .section_7 {
  padding: 60px 0px 40px 0px;
  background-image: url(../images/home/inquiry_bg.webp);
  background-size: cover;
}

#home .section_7 .inquiry_fieldset {
  width: calc(420px * 2 + 24px);
}

#home .section_7 .tit {
  text-align: left;
  color: #fff;
}

#home .section_7 .tit.type_01 {
  margin: 0px 0px -30px -12%;
  color: rgba(255, 255, 255, 0.15);
}

#home .section_7 .input_box_wrap {
  display: flex;
  gap: 24px;
}

#home .section_7 .inquiry_fieldset .input_box_wrap {
  margin-bottom: 24px;
}

#home .section_7 .inquiry_fieldset .input_box_wrap.consent {
  margin-bottom: 0px;
}

#home .section_7 .input_box_wrap.consent {
  display: flex;
  justify-content: space-between;
}

#home .section_7 .input_box_wrap.consent .txt_wrap {
  display: flex;
  align-items: center;
  gap: 12px;
}

#home .section_7 .input_box_wrap.consent .txt_wrap * {
  color: var(--subColor_04);
}

#home .section_7 .input_box_wrap .dep {
  width: 100%;
}

#home .section_7 .input_box {
  width: 100%;
  height: 50px;
  padding: 17px 16px;
  background-color: #fff;
}

#home .section_7 .input_box#inquiry {
  height: 150px;
  vertical-align: text-top;
}

#home .section_7 .input_box_wrap .view_all {
  text-decoration: underline;
}

#home .section_7 .consent_input_wrap {
  display: flex;
  gap: 48px;
}

#home .section_7 .consent_input_wrap * {
  height: 20px;
  line-height: 20px;
  color: #fff;
}

#home .section_7 .consent_input_wrap input[type=radio] {
  accent-color: #1d1e1e;
  margin-right: 8px;
}

#home .section_7 .consent_input_wrap input[type=radio]+label {
  opacity: 0.5;
}

#home .section_7 .consent_input_wrap input[type=radio]:checked+label {
  opacity: 1;
}



#home .section_7 .input_box_wrap,
#home .section_7 .input_box_wrap .dep {
  position: relative;
}

#home .section_7 .input_box_wrap .txt_overlay {
  position: absolute;
  top: 18px;
  left: 16px;
  pointer-events: none;
  color: #373948;
}

#home .section_7 .input_box_wrap .txt_overlay .point {
  color: var(--mainColor_01);
}

/* home End */

/* company Start */
.sub_page .mb_wrap {
  position: relative;
  height: 570px;
  margin-bottom: 120px;
  background-size: cover;
  background-position: center center;
}

.sub_page.company .mb_wrap {
  background-image: url(../images/company/mb_bg.webp);
}

.sub_page .mb_wrap .txt_wrap {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sub_page .mb_wrap .txt_wrap * {
  color: #fff;
}

.sub_page .mb_wrap .txt_wrap .tit {
  margin-bottom: 24px;
}

.sub_page .gnb {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.sub_page .gnb .nav {
  display: flex;
  gap: 8px;
  margin-left: auto;
}

.sub_page .gnb .nav * {
  color: #fff;
}

.sub_page .gnb .nav .arrow {
  font-size: 13px;
}

.sub_page .gnb .gnb_item_wrap {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  background-color: #fff;
}

.sub_page .gnb .gnb_item_wrap .gnb_item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 230px;
  height: 70px;
}

.sub_page .gnb .gnb_item_wrap .gnb_item.on {
  position: relative;
  color: var(--mainColor_01);
}

.sub_page .gnb .gnb_item_wrap .gnb_item.on::after {
  content: '';
  display: block;
  clear: both;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 2px;
  background-color: var(--mainColor_01);
}

.sub_page .wrap_01.type_01 {
  padding-bottom: 160px;
}

.sub_page .wrap_01 {
  padding-bottom: 120px;
}

.sub_page .banner_img {
  margin-top: 54px;
}

.about .list_wrap {
  display: flex;
  justify-content: center;
  gap: 40px;
}

.about .list_item {
  width: 256px;
}

.contactus.banner {
  width: 100%;
  height: 406px;
  padding: 80px 16px;
  background-image: url(../images/company/link_banner_bg.webp);
  background-size: cover;
}

.contactus.banner * {
  color: #fff;
}

.contactus.banner .tit {
  line-height: 60px;
  font-size: 40px;
  margin-bottom: 32px;
}

.contactus.banner .txt {
  margin-bottom: 48px;
}

.contactus.banner .a_btn {
  display: flex;
  gap: 24px;
  width: 188px;
  height: 40px;
  border-bottom: 2px solid #fff;
  transition: all 0.3s;
  margin-left: auto;
  margin-right: auto;
}

.contactus.banner .a_btn:hover,
.contactus.banner .a_btn:focus {
  gap: 58px;
}

.history .swiper-horizontal>.swiper-scrollbar {
  width: 100%;
  height: 2px;
  left: 0px;
}

.history .history_swiper {
  height: auto;
  padding-top: 8px;
  padding-bottom: 45px;
}

.history .swiper-horizontal>.swiper-scrollbar .swiper-scrollbar-drag {
  border-radius: 0px;
}

.history .history_slide {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 700px;
  border-top: 1px solid var(--subColor_04);
  padding-top: 16px;
}

.history .history_slide::before {
  z-index: 9;
  content: '';
  display: block;
  clear: both;
  position: absolute;
  top: -5px;
  left: 3px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--subColor_04);
}

.history .history_slide.swiper-slide-active::before {
  background-color: var(--mainColor_01);
}

.history .history_slide.swiper-slide-active::after {
  content: '';
  display: block;
  clear: both;
  position: absolute;
  top: -8px;
  left: 0px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fac6a8;
}

.history .history_slide .list_item {
  display: flex;
  align-items: center;
}

.history .history_slide .list_item::before {
  content: '';
  display: block;
  clear: both;
  width: 5px;
  min-width: 5px;
  height: 5px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: var(--subColor_01);
}

.history .history_slide .list_item:not(:last-of-type) {
  margin-bottom: 16px;
}

.history .swiper-slide .tit {
  margin-bottom: 32px;
  color: var(--subColor_04);
}

.history .history_slide.swiper-slide-active .tit {
  color: var(--mainColor_01);
}

.history .history_slide .history_item_wrap {
  margin-bottom: 24px;
}

.history .swiper-slide .history_img {
  width: 332px;
  margin-top: auto;
}

.contact_us .map_wrap {
  position: relative;
}

.contact_us .map_wrap .map {
  width: 100%;
  height: 590px;
  background-color: var(--subColor_05);
}

.contact_us .map_wrap .map #iframe_map {
  width: 100%;
  height: 100%;
}

.contact_us .map_wrap .contact_info_wrap {
  z-index: 9;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* pointer-events: none; */
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.contact_us .map_wrap .contact_info {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  width: 470px;
  padding: 32px 38px;
  background: rgb(230, 120, 57);
  background: linear-gradient(90deg, rgba(230, 120, 57, 1) 10%, rgba(232, 87, 5, 1) 100%);
}

.contact_us .map_wrap .contact_info * {
  color: #fff;
}

.contact_us .map_wrap .contact_info .tit::after {
  content: '';
  display: block;
  clear: both;
  width: 34px;
  height: 5px;
  margin: 14px 0px 14px 0px;
  background-color: #fff;
}

.contact_us .map_wrap .contact_info .list_item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.contact_us .map_wrap .contact_info .list_item:not(:last-of-type) {
  margin-bottom: 10px;
}

.contact_us .map_wrap .contact_info .icon {
  font-size: 21px;
}

.sub_page.product .mb_wrap {
  background-image: url(../images/product/mb_bg.webp);
}

.sub_page.customer .mb_wrap {
  background-image: url(../images/customer/mb_bg.webp);
}

.sub_page.contact .mb_wrap {
  background-image: url(../images/contact/mb_bg.webp);
}

/* company End */

/* product Start */
.product {
  padding-bottom: 140px;
}

.product .category_wrap button {
  display: block;
}

.product .category_wrap .category_dep_01_wrap {
  display: flex;
  align-items: center;
}

.product .category_wrap .category_dep_01_wrap .tit {
  margin-right: 24px;
}

.product .category_wrap .category_dep_01_wrap .category_dep_01 {
  /* overflow-x: scroll; */
  display: flex;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
}

.product .category_wrap .category_dep_01 .dep_01_item {
  display: flex;
  align-items: center;
  gap: 8px;
  width: max-content;
  border: 1px solid var(--subColor_05);
  border-radius: 9999px;
  padding: 12px 16px;
  background-color: #f5f5f5;
}

.product .category_wrap .category_dep_01 .dep_01_item.on {
  border-color: var(--mainColor_01);
  background-color: #fff;
}

.product .category_wrap .category_dep_01 .dep_01_item.on * {
  color: var(--mainColor_01);
}

.product .category_wrap .category_dep_02,
.product .category_wrap .category_dep_03 {
  /* overflow-x: scroll; */
  display: flex;
  align-items: center;
  gap: 40px;
  padding: 0px 24px;
  box-shadow: inset 0px -1px 0px 0px var(--subColor_05);
}

.product .category_wrap .category_dep_01.swiper .swiper-slide,
.product .category_wrap .category_dep_02.swiper .swiper-slide,
.product .category_wrap .category_dep_03.swiper .swiper-slide {
  width: max-content;
}


.product .category_wrap .category_dep_01::-webkit-scrollbar {
  height: 0px;
}

.product .category_wrap .category_dep_02::-webkit-scrollbar,
.product .category_wrap .category_dep_03::-webkit-scrollbar {
  height: 1px;
}

.product .category_wrap .category_dep_01::-webkit-scrollbar-thumb,
.product .category_wrap .category_dep_02::-webkit-scrollbar-thumb,
.product .category_wrap .category_dep_03::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.product .category_wrap .dep_02_item,
.product .category_wrap .dep_03_item {
  width: max-content;
}

.product .category_wrap .category_dep_02 {
  margin-top: 24px;
  background-color: #f5f5f5;
}

.product .category_wrap .dep_02_item,
.product .category_wrap .dep_03_item {
  height: 100%;
  padding: 16px 0px;
}

.product .category_wrap .dep_03_item {
  color: var(--subColor_04);
}

.product .category_wrap .dep_02_item.on,
.product .category_wrap .dep_03_item.on {
  border-bottom: 1px solid var(--mainColor_01);
  color: var(--mainColor_01);
}

.product .category_wrap .category_btn{
  cursor: pointer;
}

.product .category_wrap .category_result_wrap .tit .point {
  color: var(--mainColor_01);
}

.product .category_wrap .category_result_wrap .category_result {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.product .category_wrap .category_result_wrap .category_result .result_item {
  position: relative;
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  width: calc((1440px - (24px * 4)) / 5);
  height: 400px;
  border: 1px solid var(--subColor_05);
}

.product .category_wrap .category_result_wrap .category_result .result_item:hover,
.product .category_wrap .category_result_wrap .category_result .result_item:focus {
  border-color: var(--mainColor_01);
}

.product .category_wrap .category_result_wrap .category_result .result_item:hover::before,
.product .category_wrap .category_result_wrap .category_result .result_item:focus::before {
  content: '';
  display: block;
  clear: both;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 5px;
  background-color: var(--mainColor_01);
}

.product .category_wrap .category_result_wrap .category_result .result_item .product_img_wrap {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60%;
}

.product .category_wrap .category_result_wrap .category_result .result_item .product_img_wrap .product_img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product .category_wrap .category_result_wrap .category_result .result_item .selected_category {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px 16px;
  color: var(--subColor_04);
}

.product .category_wrap .category_result_wrap .category_result .result_item .tit {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px 10px;
  margin-top: 8px;
  margin-bottom: 28px;
}

.product .category_wrap .category_result_wrap .category_result .result_item .a_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-top: auto;
  color: var(--subColor_04);
  background-color: var(--subColor_05);
}

.product .category_wrap .category_result_wrap .category_result .result_item:hover .a_btn,
.product .category_wrap .category_result_wrap .category_result .result_item:focus .a_btn {
  color: #fff;
  background-color: var(--mainColor_01);
}

.product .category_wrap .category_result_wrap .category_result .line {
  width: 100%;
  height: 1px;
  margin: 40px 0px;
  background-color: var(--subColor_05);
}

/* product End */

/* customer Start */
.customer_notice .top_nav.type_01 {
  margin-bottom: 0px;
}

.customer_notice .list_wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.item.noitem {
  padding: 40px 0px;
}

.customer_notice .list_wrap .a_btn {
  display: flex;
  align-items: center;
  gap: 34px;
  height: 100px;
  border-bottom: 1px solid var(--subColor_05);
  padding: 0px 20px;
}

.customer_notice .list_wrap .a_btn:hover,
.customer_notice .list_wrap .a_btn:focus {
  background-color: var(--subColor_08);
}

.customer_notice .list_wrap .a_btn .types {
  display: none;
  width: 56px;
}

.customer_notice .list_wrap .a_btn .types.notice_wrap.on {
  display: block;
}

.customer_notice .list_wrap .a_btn .types.date_wrap.on {
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
}

.customer_notice .list_wrap .a_btn .types.date_wrap.on * {
  color: var(--subColor_04);
}

.customer_notice .list_wrap .a_btn:hover .types.date_wrap.on *,
.customer_notice .list_wrap .a_btn:focus .types.date_wrap.on * {
  color: var(--mainColor_01);
}

.customer_notice .list_wrap .a_btn .types.date_wrap .txt.date {
  /* font-size: 24px; */
  width: 100%;
  text-align: center;
  order: 0;
}

.customer_notice .list_wrap .a_btn .types.date_wrap .txt.year,
.customer_notice .list_wrap .a_btn .types.date_wrap .txt.month {
  width: fit-content;
  order: 1
}

.customer_notice .page_control_wrap.type_01 {
  margin-top: 0px;
}

.customer_notice_detail .contents_wrap.type_01 {
  padding: 0px 16px 40px 16px;
}

.customer_data .top_nav.type_01 {
  margin-bottom: 0px;
}

.customer_data .page_control_wrap {
  margin-top: 0px;
}

.customer_data .list_wrap .item {
  padding-right: 40px;
}

.customer_data .list_wrap .item:not(:last-of-type) {
  border-bottom: 1px solid var(--subColor_09);
}

.customer_data .list_wrap .item:hover,
.customer_data .list_wrap .item:focus {
  background-color: var(--subColor_08);
}

.customer_data .list_wrap .item .a_btn {
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 52px;
  height: 275px;
  padding: 32px 0px;
}

.customer_data .list_wrap .item .a_btn .text_wrap {
  max-width: 860px;
}

.customer_data .list_wrap .item .a_btn .text_wrap .tit {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.customer_data .list_wrap .item .thumbnail {
  object-fit: cover;
  width: 325px;
  height: 100%;
  margin-bottom: 0px;
}

.customer_data .list_wrap .item .txt {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  width: 100%;
}

.customer_data .list_wrap .item .txt * {
  word-break: break-all;
  color: var(--subColor_04);
}

.customer .btn_download .file_name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

/* customer End */

/* contact Start */
.contact_faq .top_nav.type_01 {
  margin-bottom: 0px;
}

.contact_faq .list_wrap:not(:last-of-type) {
  border-bottom: 2px solid var(--subColor_05);
}

.contact_faq .list_wrap .list .item.question.on {
  border-bottom: 2px solid var(--subColor_05);
}
.contact_faq .list_wrap .list{
  cursor: pointer;
}
.contact_faq .list_wrap .item {
  display: flex;
  align-items: center;
  gap: 40px;
  width: 100%;
  min-height: 100px;
  padding: 40px 24px;
}

.contact_faq .list_wrap .item.item_answer {
  display: none;
  background-color: var(--subColor_08);
}

.contact_faq .list_wrap .item.item_answer.on {
  display: flex;
}

.contact_faq .list_wrap .item.item_answer .tit {
  color: var(--subColor_01);
  background-color: #fff;
}

.contact_faq .list_wrap .item .tit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  min-width: 56px;
  height: 56px;
  border-radius: 50%;
  color: #fff;
  background-color: var(--mainColor_01);
}

.contact_faq .list_wrap .item .txt {
  width: 100%;
  text-align: left;
}

.contact_faq .list_wrap .item .icon {
  margin-left: auto;
  color: #333;
}
.contact_faq .list_wrap .item .icon.on{
  transform: rotate(180deg);
}

.contact_faq .page_control_wrap.type_01 {
  margin-top: 0px;
}

.contact_inquire {
  padding-bottom: 170px;
}

.contact_inquire .top_head_wrap {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 2px solid var(--subColor_01);
  padding-bottom: 32px;
}

.contact_inquire .top_head_wrap .txt .point {
  color: #ff0400;
}

.contact_inquire .inquire_from .item_01 {
  display: flex;
  gap: 40px;
}

.contact_inquire .inquire_from .input_wrap {
  margin-bottom: 32px;
}

.contact_inquire .inquire_from .input_wrap.message_wrap {
  margin-bottom: 24px;
}

.contact_inquire .inquire_from .input_wrap input,
.contact_inquire .inquire_from .input_wrap textarea {
  display: block;
  width: 700px;
  height: 52px;
  border: 1px solid var(--subColor_09);
  padding: 16px;
  margin-top: 16px;
}

.contact_inquire .inquire_from .input_wrap.message_wrap textarea {
  width: 100%;
  height: 250px;
}

.contact_inquire .inquire_from .input_wrap .point {
  color: #ff0400;
}

.contact_inquire .inquire_from .input_wrap.upload_wrap {
  display: flex;
  align-items: center;
  gap: 16px;
  padding-bottom: 32px;
  border-bottom: 1px solid #c5c5c5;
}

.contact_inquire .inquire_from .input_wrap .label_upload {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 148px;
  height: 52px;
  color: #fff;
  background-color: #7d7d7d;
}

.contact_inquire .inquire_from .input_wrap .input_upload {
  display: none;
}

.contact_inquire .privacy_wrap .info_wrap {
  padding: 32px;
  background-color: #f9f9f9;
}

.contact_inquire .privacy_wrap .info_wrap .list_wrap .item {
  display: flex;
  gap: 45px;
}

.contact_inquire .privacy_wrap .info_wrap .list_wrap .item .tit,
.contact_inquire .privacy_wrap .info_wrap .list_wrap .item .txt {
  color: #999;
}

.contact_inquire .privacy_wrap .info_wrap .list_wrap .item .tit {
  width: 105px;
}

.contact_inquire .btn_submit {
  display: block;
  width: 870px;
  height: 70px;
  margin-left: auto;
  margin-right: auto;
  color: #fff;
  background-color: var(--subColor_02);
}

.contact_inquire .privacy_agree_wrap {
  display: flex;
  align-items: center;
  gap: 8px;
}

.contact_inquire input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border: 1px solid #bfbfbf;
  border-radius: 0px;
  position: relative;
}

.contact_inquire input[type="checkbox"]:checked {
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
  appearance: checkbox;
}

/* contact End */

/* ft Start */
#footer {
  padding: 18px 0px 32px 0px;
  background-color: var(--subColor_01);
}

#footer * {
  color: rgba(255, 255, 255, 0.4);
}

#footer .point {
  color: #fff;
}

#footer .logo {
  width: auto;
  height: 48px;
  margin-bottom: 14px;
}

#footer .dep_01 {
  display: flex;
  justify-content: space-between;
}

#footer .dep_01.item_top {
  align-items: flex-start;
}

#footer .dep_01 .txt_wrap .item_01 {
  margin-bottom: 14px;
}

#footer .dep_01 .txt_wrap .txt {
  margin-bottom: 7px;
}

#footer .dep_01 .ft_gnb_wrap {
  display: flex;
  padding-top: 14px;
}

#footer .dep_01 .ft_gnb_wrap .item {
  width: 190px;
  height: 155px;
  text-align: center;
  border-right: 1px solid var(--subColor_03);
}

#footer .dep_01 .ft_gnb_wrap .item:first-of-type {
  border-left: 1px solid var(--subColor_03);
}

#footer .dep_01 .ft_gnb_wrap .item .tit {
  margin-bottom: 27px;
}

#footer .dep_01 .ft_gnb_wrap .item .dep_02_item:not(:last-of-type)>.a_btn {
  margin-bottom: 14px;
}

#footer .dep_01.item_bottom {
  align-items: center;
  margin-top: 44px;
}

#footer .sns_wrap {
  display: flex;
  gap: 8px;
}

#footer .sns_wrap .sns_item {
  width: 35px;
  height: 35px;
}

/* ft End */

/* Notfound Start */
.notfound {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.notfound .txt_wrap {
  text-align: center;
}

.notfound .txt_wrap .xi-error {
  font-size: 60px;
  color: var(--subColor_04);
}

.notfound .txt_wrap .tit {
  line-height: 60px;
}

.notfound .txt_wrap .btn_a.type_01 {
  margin-left: auto;
  margin-right: auto;
}

/* Notfound End */

/* PrivacyAgreement All Start */
.privacy_agreement_all_wrap {
  z-index: 9999;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4)
}

.privacy_agreement_all_wrap .privacy_agreement_all {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 452px;
  background-color: #fff;
}

.privacy_agreement_all_wrap .privacy_agreement_all .title_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 53px;
  padding: 0px 24px;
  background-color: var(--subColor_01);
}

.privacy_agreement_all_wrap .privacy_agreement_all .title_wrap * {
  color: #fff;
}

.privacy_agreement_all_wrap .text_wrap {
  padding: 24px;
}

.privacy_agreement_all_wrap .text_wrap .item {
  display: flex;
  gap: 45px;
}

.privacy_agreement_all_wrap .text_wrap .item * {
  color: #999;
}

.privacy_agreement_all_wrap .text_wrap .item .tit {
  width: 90px;
  min-width: 90px;
}

/* PrivacyAgreement All End */

/* 팝업 */
.pop-parent {
  z-index: 9999;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  padding-bottom: 40px;
  background-color: rgba(0, 0, 0, 0.35);
}

.pop-parent .pop {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
}
.pop-parent .pop .swiper-pagination{
  pointer-events: none;
}

.pop-parent .m-ratioBox-wrap {
  width: 100%;
  padding-top: 100%;
  position: relative;
  overflow: hidden;
}

.pop-parent .m-ratioBox-wrap .m-ratioBox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-color: var(--subColor_01);
}

.pop-parent .m-ratioBox-wrap img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 1;
}

.pop-parent .m-ratioBox-wrap iframe {
  width: 105%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 1;
}

.pop-parent .pop .btn-toggle {
  width: 40px;
  height: 40px;
  position: relative;
  background-color: black;
  cursor: pointer;
}

.pop-parent .pop .btn-toggle img {
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-90deg);
  transition: all .3s;
}

.pop-parent .pop-btns {
  z-index: 9;
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  background-color: #3c3c3c;
}

.pop-parent .pop-btns a {
  color: #fff;
  padding: 7px 14px;
}

.pop-parent .pop-btns #oneday_check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}

.pop-parent .pop-btns #oneday_check+label {
  display: flex;
  align-items: center;
  padding: 0 10px;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
}

.pop-parent .pop-btns #oneday_check+label:before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 10px;
  border: 1px solid #fff;
}

.pop-parent .pop .swiper-btn-control {
  width: 8px;
  height: 11px;
  margin-left: 16px;
  position: relative;
  top: 2px;
}

.pop-parent .swiper-control {
  display: flex;
  align-items: center;
  position: absolute;
  top: 21px;
  right: 20px;
  z-index: 2;
}

#root .pop-parent .swiper-pagination {
  top: 21px;
  left: auto;
  right: 20px;
  width: fit-content;
}

/* .swiper-pagination */

.pop-parent .swiper-pagination-bullet {
  flex: 0 0 auto;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  border-radius: 0;
  border: 1px solid white;
  opacity: 0.5;
  background-color: transparent;
}

.pop-parent .swiper-pagination-bullet-active {
  background-color: #fff;
  opacity: 1;
}

.pop-parent.active {
  right: -400px;
}

.pop-parent.active .btn-toggle {
  transform: rotate(180deg);
}

.pop-parent .pop .btn-close {
  width: 40px;
  height: 40px;
  background-color: black;
}

/* aside Start */
.aside_wrap {
  display: none;
  z-index: 9980;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.aside_wrap.on {
  display: block;
}

#aside {
  position: fixed;
  top: 0px;
  right: 0px;
  display: flex;
  align-items: flex-start;
}

#aside .btn_close {
  width: 80px;
  height: 80px;
  color: #fff;
  background-color: var(--mainColor_01);
}

#aside .contents_wrap {
  width: 350px;
  height: 100vh;
  background-color: #fff;
}

#aside .contents_wrap .logo_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  height: 80px;
}

#aside .contents_wrap .logo_wrap .logo {
  width: auto;
  height: 42px;
}

#aside .contents_wrap .item_wrap .item .btn {
  height: 50px;
  line-height: 50px;
  padding: 0px 24px;
}

#aside .contents_wrap .item_wrap .item .btn.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 60px;
  line-height: 60px;
  border-bottom: 1px solid #efefef;
}
#aside .contents_wrap .item_wrap .item .btn.main:focus{
  outline: 0px;
}
#aside .contents_wrap .item_wrap .item .btn.main .icon_arrow.on{
  transform: rotate(180deg);
}

#aside .contents_wrap .item_wrap .btn_sub_wrap .btn.sub {
  background-color: var(--subColor_08);
}

.btn_sub_wrap {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s 0s ease;
}

.btn_sub_wrap.open {
  max-height: 500px;
  /* 충분히 큰 값으로 설정 */
}

/* aside End */

/* top Btn Start */
.Top_Scroll_btn {
  z-index: 999;
  position: fixed;
  bottom: 48px;
  right: 48px;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: .3s;
}

.Top_Scroll_btn.active {
  opacity: 1;
}

.Top_Scroll_btn i {
  font-size: 24px;
  color: #464646;
  z-index: 2;
}

.Top_Scroll_btn .progress_bar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}

.Top_Scroll_btn .progress_bar::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 90%;
  background-color: #fff;
  border-radius: 100%;
}

/* top Btn End */

@media screen and (max-width: 1500px) {
  .sub_page .wrap_01 {
    padding-left: 16px;
    padding-right: 16px;
  }

  .contents_wrap.type_01 {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }

  .customer .wrap,
  .contact .wrap {
    padding-left: 16px;
    padding-right: 16px;
  }

  .sub_page .gnb {
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }

  #home .section_02,
  #home .section_06,
  #home .section_7 {
    padding-left: 24px;
    padding-right: 24px;
  }

  #home .section_01 .item_01 {
    width: 100%;
  }

  #home .mb_swiper_wrap .swiper-slide .tit {
    line-height: 68px;
    font-size: 52px;
    margin-bottom: 24px;
  }

  #home .section_04 .list_item {
    width: 210px;
    height: 210px;
  }

  #home .item_02 .sub {
    width: 200px;
  }

  #home .section_02 .item_01 .txt_wrap {
    margin-bottom: 40px;
  }

  #home .section_03 .swiper_02_nav_wrap {
    padding: 0px 24px;
  }
  #home .section_04 {
    padding-left: 16px;
    padding-right: 16px;
  }

  #home .section_06 {
    display: block;
  }

  #home .section_06 .tit.type_01,
  #home .section_06 .tit.type_02,
  #home .section_06 .tit.type_02+.txt {

    text-align: center;
  }

  #home .section_06 .tit.type_01 {
    margin-left: 0px;
  }

  #root #home .section_06 .btn_a {
    display: none;
  }

  #home .section_06 .list_wrap {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  /* product Start */
  .product .wrap {
    padding-left: 16px;
    padding-right: 16px;
  }

  .product .category_wrap .category_result_wrap .category_result {
    gap: 16px;
  }

  .product .category_wrap .category_result_wrap .category_result .result_item {
    width: calc((100% - (16px * 4)) / 5);
  }

  /* product End */

  /* contact Start */
  .contact_inquire .inquire_from .item_01 {
    display: block;
  }

  /* contact End */
  .info_wrap.type_01,
  .control_wrap.type_01 {
    width: calc(100% - 32px);
  }

  #footer {
    padding-left: 16px;
    padding-right: 16px;
  }
}

@media screen and (max-width:1200px) {
  .lh_type_01 {
    line-height: 42px;
  }

  .fs_type_02 {
    font-size: 28px;
  }

  .fs_type_08 {
    font-size: 15px;
  }

  #gnb .category_wrap {
    display: none;
  }

  #home .mb_swiper_nav_wrap {
    display: none;
  }

  #home .mb_swiper_wrap .swiper-slide .tit_wrap {
    padding: 0px 40px;
  }

  #home .item_02 .sub {
    width: 160px;
  }

  #root #home .btn_a.type_01.pc {
    display: none;
  }

  #root #home .btn_a.type_01.mo {
    display: flex;
  }

  #home .section_02 .mv.pc {
    display: none;
  }

  #home .section_02 .item_01 {
    display: block;
  }

  #home .section_02 .item_01 .sub_tit {
    font-size: 20px;
  }

  #home .section_02 .item_01 .txt_wrap .btn_a {
    margin-top: 40px;
  }

  #home .section_02 .mv.mo {
    display: block;
  }

  #home .section_04 {
    padding: 80px 0px;
  }
  #home .section_04>.wrap{
    display: block;
    height: auto;
    padding: 0px 16px;
  }
  #home .section_04>.wrap .tit{
    text-align: center;
  }
  #home .section_04>.wrap .mv{
    display: block;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
  }


  #home .section_7 .inquiry_fieldset {
    width: 100%;
  }

  /* contact Start */
  .contact_inquire .btn_submit {
    max-width: 500px;
  }

  /* contact End */
  #footer {
    padding: 24px 40px;
  }

  #footer .dep_01 {
    display: block;
    flex-wrap: wrap;
  }

  #footer .dep_01 .txt_wrap {
    margin-bottom: 40px;
  }

  #footer .dep_01 .txt_wrap .mo_wrap {
    display: flex;
  }

  #footer .dep_01 .ft_gnb_wrap {
    display: none;
  }

  #footer .dep_01 .txt_wrap .mo_wrap .txt:not(:last-of-type) {
    border-right: 1px solid #404040;
    padding-right: 8px;
    margin-right: 8px;
  }

  #footer .sns_wrap {
    margin-top: 24px;
  }

  /* popup Start */
  .pop-parent .pop {
    width: 310px;
  }

  .pop-parent.active {
    right: -310px;
  }

  /* popup End */
}

@media screen and (max-width:1023px) {
  #home .section_01 {
    display: block;
  }

  #home .section_01 .item_01 * {
    text-align: center;
  }

  #home .section_01 .item_01 {
    width: calc(100% - 32px);
    margin-left: auto;
    margin-right: auto;
  }

  #home .section_01 .item_02 {
    justify-content: center;
  }

  #home .item_02 .sub {
    width: 33%;
  }

  #home .section_02 .item_01 {
    display: block;
    padding: 0px 24px;
  }

  .product .category_wrap .category_result_wrap .category_result .result_item {
    height: 320px;
  }
}

/* mobile Start */
@media screen and (max-width:768px) {

  /* common Start */
  .lh_type_08,
  .lh_type_09 {
    line-height: 32px;
  }

  .lh_type_07 {
    line-height: 22px;
  }

  .lh_type_01 {
    line-height: 28px;
  }

  .lh_type_04 {
    line-height: 24px;
  }

  .lh_type_03,
  .lh_type_05 {
    line-height: 22px;
  }

  .fs_type_01,
  .fs_type_10 {
    font-size: 24px;
  }

  .fs_type_02 {
    font-size: 18px;
  }

  .fs_type_12,
  .fs_type_16 {
    font-size: 20px;
  }

  .fs_type_05,
  .fs_type_13,
  .fs_type_14 {
    font-size: 16px;
  }

  .fs_type_06,
  .fs_type_07 {
    font-size: 13px;
  }

  .fs_type_08,
  .fs_type_11 {
    font-size: 13px;
  }

  .mt_24 {
    margin-top: 16px;
  }

  .mt_40 {
    margin-top: 32px;
  }

  .mt_130 {
    margin-top: 90px;
  }

  .mb_16 {
    margin-bottom: 8px;
  }

  .mb_60 {
    margin-bottom: 40px;
  }

  .mb_80 {
    margin-bottom: 40px;
  }

  .mb_120 {
    margin-bottom: 80px;
  }

  .section_01 .tit+.txt {
    margin-top: 14px;
  }

  #root .btn_a.type_01.pc {
    display: none;
  }

  #root .btn_a.type_01.mo {
    display: flex;
  }

  .btn.type_01 {
    width: 120px;
    height: 40px;
  }

  .btn_a.type_01::after {
    width: 30px;
    height: 30px;
  }

  .tit.type_04 {
    margin-bottom: 40px;
  }

  /* common End */

  /* header Start */
  #header {
    height: 60px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }

  #gnb .gnb_ul {
    padding: 0px 16px;
  }

  #gnb .gnb_icon {
    font-size: 32px;
  }

  #gnb .logo {
    width: auto;
    height: 31px;
  }

  .gnb_item_category {
    display: none;
  }

  /* header End */

  /* contact Start */
  .contactus.banner {
    height: auto;
    padding: 48px 0px;
    background-position: center center;
  }

  .contactus.banner .tit {
    width: 300px;
    line-height: 32px;
    font-size: 20px;
    margin: 0px auto 24px auto;
  }

  .contactus.banner .txt {
    margin-bottom: 40px;
  }

  .contactus.banner .a_btn {
    gap: 16px;
    width: fit-content;
    height: 24px;
  }

  /* contact End */

  /* aside Start */
  #aside .contents_wrap {
    max-width: 280px;
    width: calc(100vw - 56px);
  }

  #aside .btn_close {
    width: 56px;
    height: 56px;
    font-size: 24px;
  }

  #aside .contents_wrap .logo_wrap {
    height: 56px;
  }

  #aside .contents_wrap .logo_wrap .logo {
    width: 40px;
    height: auto;
  }

  #aside .contents_wrap .item_wrap .item .btn.main {
    height: 56px;
  }

  #aside .contents_wrap .item_wrap .item .btn {
    height: 40px;
    line-height: 40px;
  }

  /* aside End */

  /* home Start */
  #home .section_01,
  #home .section_06,
  #home .section_02 .item_01 {
    display: block;
  }

  .mb_swiper_nav_wrap,
  #home .section_06 .list_wrap,
  #home .section_7 .inquiry_fieldset {
    width: 100%;
  }

  #home .tit.type_02 {
    font-size: 32px;
  }

  #home .mb_swiper_wrap {
    height: 100vh;
  }

  #home .main_banner {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  #home .mb_swiper_wrap .swiper-slide .tit_wrap {
    padding: 0px 30px 0px 16px;
  }

  #home .mb_swiper_wrap .swiper-slide .tit_wrap .tit {
    line-height: 46px;
    font-size: 36px;
    margin-bottom: 36px;
  }

  #home .mb_swiper_wrap .swiper-slide .tit_wrap .fs_type_06 {
    font-size: 14px;
  }

  #home .mb_swiper_nav_wrap {
    display: none;
  }

  #home .tit.type_01 {
    font-size: 58px;
  }

  #home .item_02 .sub {
    width: 33%;
    height: 108px;
  }

  #home .item_02 .sub:not(:last-of-type)::after {
    height: 31px;
  }

  #home .item_02 .sub .icon {
    height: 35px;
  }

  #home .section_01 .item_01 {
    width: calc(100% - 32px);
    height: auto;
    padding: 24px;
    margin-left: auto;
    margin-right: auto;
    background-size: cover;
    background-position: top center;
  }

  #home .section_01 .item_01 * {
    text-align: center;
  }

  #home .section_02 .item_01 {
    padding: 80px 16px 24px 16px;
  }

  #home .section_02 .item_01 .sub_tit {
    font-size: 13px;
  }

  #home .section_02 .item_01 .txt_wrap .tit {
    margin-bottom: 24px;
  }

  #home .section_02 .item_01 .btn_a.type_01 {
    gap: 26px;
    margin-top: 24px;
  }

  #home .section_02 .item_01 .btn_a.type_01 * {
    font-size: 12px;
  }

  #home .section_02 .item_01 .zeatech_img {
    width: auto;
    height: 40px;
    margin-bottom: 17px;
  }

  #home .section_02 .mv {
    width: 100%;
    height: auto;
  }

  #home .section_02 .mv.pc {
    display: none;
  }

  #home .section_03 {
    padding: 80px 0px;
  }

  #home .section_03 .swiper_02 .swiper-slide {
    flex-direction: row;
    width: 270px;
    height: auto;
  }

  #home .section_03 .swiper_02 .swiper-slide .product_img_wrap {
    width: 100%;
    height: auto
  }

  #home .section_03 .swiper_02_nav_wrap {
    display: none;
  }

  #home .section_03 .tit_02+.txt {
    margin-bottom: 32px;
  }

  #home .section_03 .swiper_02 .swiper-slide {
    padding: 32px 24px;
  }

  #home .section_04 {
    background-position: center center;
  }

  #home .section_04 .logo {
    width: auto;
    height: 55px;
  }

  #home .section_04 .logo+.txt {
    font-size: 13px;
    padding: 0px 52px;
  }

  #home .section_04 .list_wrap {
    justify-content: center;
    flex-wrap: wrap;
    gap: 16px 24px;
    width: auto;
  }

  #home .section_04 .list_item {
    flex: auto;
    width: 160px;
    max-width: 160px;
    max-height: 160px;
  }

  #home .section_04 .list_item:not(:first-of-type) {
    margin: 0px;
  }

  #home .section_05 {
    padding: 120px 0px;
  }

  #home .section_05 .list_wrap {
    gap: 40px 0px;
    justify-content: center;
    padding: 0px 16px;
  }

  #home .section_05 .list_wrap .item {
    width: 50%;
  }

  #home .section_05 .list_wrap .item .logo {
    width: 100%;
    height: auto;
  }

  #home .section_06 {
    padding-bottom: 120px;
  }

  #home .section_06 .tit.type_01 {
    text-align: center;
    margin-left: 0px;
  }

  #home .section_06 .tit.type_02,
  #home .section_06 .tit.type_02+.txt {
    text-align: center;
  }

  #home .section_06 .list_wrap {
    /* width: calc(100% - 32px); */
    border-width: 1px;
    margin: 0px auto 0px auto;
  }

  #home .section_06 .list_wrap .notice_item .btn {
    padding: 17px 0px;
  }

  #home .section_06 .list_wrap .notice_item .btn .tit {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: auto;
    font-size: 13px;
    padding-right: 10px;
  }

  #home .section_06 .list_wrap .notice_item .btn .txt {
    min-width: 75px;
  }

  #home .section_7 {
    padding: 60px 16px 40px 16px;
  }

  #home .section_7 .tit.type_01 {
    margin: 0px 0px -20px 0px;
  }

  #home .section_7 .input_box_wrap {
    flex-direction: column;
  }

  #home .section_7 .input_box {
    height: 48px;
  }

  #home .section_7 .input_box_wrap.consent {
    display: block;
  }

  #home .section_7 .consent_input_wrap {
    gap: 16px;
  }

  #home .section_7 .input_box_wrap.consent .txt_wrap {
    margin-bottom: 24px;
  }

  #home .section_7 .consent_input_wrap input[type=radio] {
    margin-right: 5px;
  }

  #home .section_7 .consent_input_wrap input[type=radio]+label {
    font-size: 13px;
  }

  /* home End */

  /* sub page Start */
  .sub_page .wrap_01 {
    padding-bottom: 80px;
  }

  .sub_page .mb_wrap {
    height: 280px;
    margin-bottom: 88px;
  }

  .sub_page .gnb {
    width: 100%;
  }

  .sub_page .mb_wrap .txt_wrap {
    width: 100%;
  }

  .sub_page .mb_wrap .txt_wrap .tit {
    margin-bottom: 16px;
  }

  .sub_page .gnb {
    left: 0px;
    transform: translateY(100%);
  }

  .sub_page .gnb .nav {
    display: none;
  }

  .sub_page .mb_wrap .txt_wrap {
    top: calc(50% + 30px);
  }

  .sub_page .gnb .gnb_item_wrap {
    margin-top: 0px;
  }

  .sub_page .gnb .gnb_item_wrap .item {
    width: 100%;
    max-width: 125px;
  }

  .sub_page .gnb .gnb_item_wrap .item .gnb_item {
    width: 100%;
    height: 48px;
  }

  .sub_page .wrap_01 {
    padding-left: 24px;
    padding-right: 24px;
  }

  .sub_page .wrap_01.type_01 {
    padding-bottom: 80px;
  }

  .sub_page .banner_img {
    margin-top: 32px;
  }

  .top_nav.type_01 {
    display: block;
  }

  .top_nav.type_01 .tit {
    text-align: center;
    margin-bottom: 24px;
  }

  .top_nav.type_01 .search_box {
    width: 100%;
    height: 44px;
    border-width: 1px;
    padding-left: 16px;
  }

  /* sub page End */

  /* company Start */
  .about .tit.type_03 {
    margin-bottom: 24px;
  }

  .about .list_wrap {
    flex-wrap: wrap;
    gap: 16px;
  }

  .about .list_wrap .list_item {
    width: calc(50% - 8px);
  }

  .history .history_swiper {
    overflow: visible;
    height: auto;
  }

  .history .history_swiper .swiper-wrapper {
    position: relative;
    display: block;
  }

  .history .history_swiper .swiper-wrapper::after {
    content: '';
    display: block;
    clear: both;
    z-index: -9;
    position: absolute;
    top: 4px;
    left: 2px;
    width: 1px;
    height: calc(100% - 8px);
    background-color: var(--subColor_05);
  }

  .history .history_swiper .history_slide {
    width: 100%;
    border: 0px none;
    padding-top: 0px;
    margin-bottom: 32px;
  }

  .history .history_swiper .history_slide::before {
    top: 6px;
    transform: translateX(-50%);
    width: 7px;
    height: 7px;
  }

  .history .history_swiper .history_slide.swiper-slide-active::after {
    top: 3px;
    left: 0px;
    transform: translateX(calc(-50% + 3px));
    width: 13px;
    height: 13px;
  }

  .history .history_slide .list_item {
    align-items: flex-start;
  }

  .history .history_swiper .history_slide .list_item::before {
    margin-right: 16px;
    margin-top: 10px;
  }

  .history .history_swiper .swiper-slide .tit {
    margin-left: calc(16px + 5px);
    margin-bottom: 24px;
  }

  .history .swiper-slide .history_img {
    width: 100%;
    padding-left: 21px;
    margin-top: 24px;
  }

  .contact_us .map_wrap .map {
    height: 240px;
  }

  .contact_us .map_wrap .contact_info_wrap,
  .contact_us .map_wrap .contact_info {
    position: static;
    transform: translate(0px);
  }

  .contact_us .map_wrap .contact_info_wrap {
    pointer-events: all;
  }

  .contact_us .map_wrap .contact_info {
    width: 100%;
    padding: 16px 24px;
  }

  /* company End */

  /* product Start */
  .product {
    padding-bottom: 0px;
  }

  .product .wrap,
  .customer .wrap,
  .contact .wrap {
    padding-left: 24px;
    padding-right: 24px;
  }

  .product .category_wrap .category_dep_01_wrap {
    display: block;
  }

  .product .category_wrap .category_dep_01_wrap .tit {
    margin-bottom: 8px;
  }

  .product .category_wrap .category_dep_01 .dep_01_item {
    gap: 4px;
    padding: 9px 8px;
  }

  .product .category_wrap .category_dep_02 {
    border-top: 1px solid var(--subColor_05);
    margin-top: 16px;
  }

  /* .product .category_wrap .category_dep_02,
  .product .category_wrap .category_dep_03 {
    overflow-x: scroll;
  } */

  .product .category_wrap .dep_02_item,
  .product .category_wrap .dep_03_item {
    width: max-content;
    padding: 15px 0px;
  }

  .product .category_wrap .category_result_wrap .category_result {
    gap: 16px;
  }

  .product .category_wrap .category_result_wrap .category_result .result_item {
    width: calc(50% - 8px);
    height: 285px;
  }

  .product .category_wrap .category_result_wrap .category_result .result_item .tit {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    white-space: normal;
    height: 44px;
    margin-bottom: 10px;
  }

  .product .category_wrap .category_result_wrap .category_result .result_item .a_btn {
    height: 48px;
    /* margin-top: auto; */
  }

  .product .category_wrap .category_result_wrap .category_result .line {
    margin: 0px;
  }

  .page_control_wrap.type_01 {
    margin-top: 16px;
    /* margin-bottom: 0px; */
  }

  .customer_notice .list_wrap .a_btn .types.date_wrap .txt.fs_type_11 {
    font-size: 12px;
  }

  /* product End */

  /* customer Start */
  .customer_notice .list_wrap .a_btn {
    gap: 8px;
    height: 48px;
    padding: 0px 8px;
  }

  .customer_notice .list_wrap .a_btn .tit {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .customer_notice .list_wrap .a_btn .types {
    width: 30px;
    padding: 0px 1px;
  }

  .customer_notice .list_wrap .a_btn .types.date_wrap.on {
    width: 30px;
    height: auto;
  }

  #root .list_wrap .a_btn .icon_wrap.type_01.pc {
    display: none;
  }

  .customer_data .list_wrap .item {
    padding-right: 0px;
  }

  .customer_data .list_wrap .item .a_btn {
    gap: 0px;
    justify-content: space-between;
    width: 100%;
    height: auto;
    padding: 16px 0px;
  }

  .customer_data .list_wrap .item .text_wrap {
    width: 100%;
  }

  .customer_data .list_wrap .item .txt {
    max-width: 265px;
    height: auto;
    -webkit-line-clamp: 2;
  }

  .customer_data .page_control_wrap.type_01 {
    margin-top: 0px;
  }

  .contact_faq .list_wrap .item {
    gap: 16px;
    min-height: auto;
    padding: 10px 0px;
  }

  .contact_faq .list_wrap .item .tit {
    width: 28px;
    min-width: 28px;
    height: 28px;
  }

  .contact_inquire {
    padding-bottom: 80px;
  }

  .contact_inquire .inquire_from .input_wrap input,
  .contact_inquire .inquire_from .input_wrap textarea {
    width: 100%;
    height: 48px;
  }

  .contact_inquire .inquire_from .input_wrap.message_wrap textarea {
    height: 175px;
  }

  .contact_inquire .inquire_from .item_01 {
    display: block;
  }

  .contact_inquire .privacy_wrap .info_wrap {
    padding: 24px 16px;
  }

  .contact_inquire .privacy_wrap .info_wrap .list_wrap .item {
    display: block;
  }

  .contact_inquire .privacy_wrap .info_wrap {
    margin-bottom: 24px;
  }

  .contact_inquire .privacy_agree_wrap .privacy_agree_label {
    font-size: 12px;
  }

  .contact_inquire .btn_submit {
    width: 100%;
    height: 58px;
  }

  .contact_inquire .top_head_wrap {
    border: 0px none;
    margin-bottom: 0px;
  }

  .contact_inquire .top_head_wrap .tit {
    width: 100%;
    text-align: center;
    font-size: 24px;
  }

  .contact_inquire .inquire_from .input_wrap {
    margin-bottom: 24px;
  }

  .contact_inquire .inquire_from .input_wrap * {
    font-size: 15px;
  }

  .contact_inquire .inquire_from .input_wrap input {
    font-size: 14px;
  }

  .contact_inquire .inquire_from .input_wrap .label_upload {
    width: 120px;
    height: 40px;
    font-size: 13px;
    font-weight: 500;
  }

  .contact_inquire .inquire_from .input_wrap .file_name_display {
    max-width: 175px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /* customer End */

  /* detail Start */
  .contents_wrap.type_01 {
    padding-bottom: 24px
  }
  .control_wrap.type_01 .btn .noitem{
    opacity: 0.4;
  }

  .contents_wrap.type_01 .iframe_container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0; overflow: hidden;
    }
  .contents_wrap.type_01 .iframe_container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .control_wrap.type_01 {
    margin-bottom: 80px;
  }

  .control_wrap.type_01 .btn .tit {
    display: none;
  }

  .control_wrap.type_01 .btn_list {
    width: 36px;
    height: 36px;
    border-width: 1px;
  }

  /* detail Start */

  /* footer Start */
  #footer {
    padding: 40px 24px;
  }

  #footer .logo {
    height: 31px;
  }

  #footer .dep_01 {
    width: 100%;
    flex-wrap: wrap;
  }

  #footer .dep_01 .ft_gnb_wrap {
    display: none;
  }

  #footer .dep_01 .txt_wrap .txt {
    font-size: 13px;
  }

  #footer .dep_01.item_bottom {
    margin-top: 24px;
  }

  #footer .dep_01 .copy {
    order: 1;
    font-size: 11px;
  }

  #footer .dep_01 .sns_wrap {
    order: 0;
    margin-bottom: 24px;
  }

  #footer .dep_01 .sns_wrap .sns_item {
    width: 25px;
    height: 25px;
  }

  /* footer End */

  /* popup Start */
  .pop-parent {
    top: 50%;
    right: unset;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .pop-parent .pop-btns {
    transform: translateY(100%);
    top: unset;
    bottom: 0;
    left: 0;
    width: 100%;
    justify-content: space-between;
  }

  .pop-parent .pop .btn-toggle {
    display: none;
  }

  .pop-parent .pop .btn-close {
    display: block;
  }

  /* popup End */

  /* privacy Start */
  .privacy_agreement_all_wrap .privacy_agreement_all {
    width: calc(100% - 32px);
  }

  .privacy_agreement_all_wrap .text_wrap .fs_type_08 {
    font-size: 14px;
    margin-bottom: 24px;
  }

  /* privacy End */

  /* top btn Start */
  .Top_Scroll_btn {
    right: 20px;
    bottom: 32px;
  }

  /* top btn End */
}

@media screen and (max-width:350px) {
  #home .tit.type_01 {
    font-size: 54px;
  }

  .privacy_agreement_all_wrap .text_wrap .item {
    flex-direction: column;
    gap: 8px;
    margin-bottom: 24px;
  }

  .privacy_agreement_all_wrap .text_wrap .item .tit {
    color: var(--subColor_01);
  }

  #footer .dep_01 .txt_wrap .mo_wrap {
    flex-wrap: wrap;
  }

  #footer .dep_01 .txt_wrap .mo_wrap .txt:not(:last-of-type) {
    border: 0px none
  }
}

/* mobile End */